import { Controller } from "@hotwired/stimulus"
import moment from 'moment' // Importing Moment.js

export default class extends Controller {
  static targets = ["tbody"]

  connect() {
    // Trigger default sort on the column with data-sort-default attribute
    const defaultSortHeader = this.element.querySelector("thead th[data-sort-default]")
    if (defaultSortHeader) {
      const defaultOrder = defaultSortHeader.dataset.sortOrder || 'asc'
      this.sort({
        currentTarget: defaultSortHeader,
        defaultOrder: defaultOrder
      })
    }
  }

  sort(event) {
    const th = event.currentTarget
    const column = th.dataset.sortColumn
    const currentOrder = th.dataset.sortOrder || 'asc'
    const isAscending = currentOrder === 'asc'

    const rows = Array.from(this.tbodyTarget.rows)

    rows.sort((a, b) => {
      const aText = a.querySelector(`td:nth-child(${this.getColumnIndex(column)})`).textContent.trim()
      const bText = b.querySelector(`td:nth-child(${this.getColumnIndex(column)})`).textContent.trim()

      const aValue = this.parseValue(aText)
      const bValue = this.parseValue(bText)

      if (aValue !== null && bValue !== null) {
        return isAscending ? aValue - bValue : bValue - aValue
      } else {
        return isAscending ? aText.localeCompare(bText) : bText.localeCompare(aText)
      }
    })

    this.tbodyTarget.append(...rows)
    this.updateSortClasses(th, !isAscending)

    // Update sort order attribute on the header
    th.dataset.sortOrder = isAscending ? 'desc' : 'asc'
  }

  getColumnIndex(column) {
    const headers = Array.from(this.element.querySelectorAll("thead th"))
    return headers.findIndex(th => th.dataset.sortColumn === column) + 1
  }

  updateSortClasses(th, isAscending) {
    // Remove sorting_asc and sorting_desc classes from all headers
    this.element.querySelectorAll("thead th").forEach(header => {
      header.classList.remove("sorting_asc", "sorting_desc")
    })

    // Add appropriate class to the clicked header
    if (isAscending) {
      th.classList.add("sorting_asc")
    } else {
      th.classList.add("sorting_desc")
    }
  }

  // Parse values using Moment.js to handle multiple date formats
  parseValue(value) {
    // Try to parse the value as a date in different possible formats
    const dateFormats = ['DD MMM, YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'MMMM D, YYYY']
    const parsedDate = moment(value, dateFormats, true)

    if (parsedDate.isValid()) {
      return parsedDate.valueOf() // Return timestamp
    }

    // If the value is a number
    if (!isNaN(value)) {
      return parseFloat(value)
    }

    // Fallback to string comparison if not date or number
    return null
  }
}
